<template>
  <div>
    <OWCATInput/>
  </div>
</template>

<script>
// @ is an alias to /src
import OWCATInput from "@/components/OWCATInput.vue";

export default {
  name: "UploadPage",
  components: {
    OWCATInput
  }
};
</script>
