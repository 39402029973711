
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
// import axios from "axios";
import { Auth } from "aws-amplify";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope as faEnvelopeSolid,
  faUser as faUserSolid,
  faKeyboard as faKeyboardSolid,
  faEye,
  faEyeSlash,
  faSignIn
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope as faEnvelopeRegular,
  faUser as faUserRegular,
  faKeyboard as faKeyboardRegular,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { bus, regex } from "@/main";

library.add(
  faEnvelopeRegular,
  faEnvelopeSolid,
  faUserRegular,
  faUserSolid,
  faKeyboardRegular,
  faKeyboardSolid,
  faEye,
  faEyeSlash,
  faSignIn
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class Regsiter extends Vue {
  public username = "";
  public name = "";
  public password = "";
  public confirmPassword = "";

  public submitted = false;

  public valids = {
    username: false,
    name: false,
    password: false
  };
  public showPassword = false;

  public checkValid(name: string): void {
    let valid = false;
    switch (name) {
      case "username":
        // Requires better checks
        if (this.username.match(regex.email)) {
          valid = true;
        }
        break;
      case "name":
        // Requires better checks
        if (this.name.length > 2) {
          valid = true;
        }
        break;
      case "password":
        // Requires better checks
        if (
          this.password.match(regex.password) &&
          this.confirmPassword === this.password
        ) {
          valid = true;
        }
        break;
    }
    Vue.set(this.valids, name, valid);
  }

  public isFormValid(): boolean {
    return !Object.values(this.valids).includes(false);
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public async handleSubmit(): Promise<void> {
    if (!this.isFormValid()) {
      return;
    }
    try {
      await Auth.signUp({
        username: this.username,
        password: this.password,
        attributes: {
          name: this.name  
        }
      });
      bus.$emit("flashNotice", {
        type: "success",
        message: "Application submitted"
      });
      this.submitted = true;
    } catch (err) {
      bus.$emit("flashNotice", {
        type: "error",
        message: `Application error: ${(err as { message:string }).message}`
      });
    }
  }
}
