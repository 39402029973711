
import { Component, Vue } from "vue-property-decorator";
import { Auth } from "aws-amplify";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope as faEnvelopeSolid,
  faKeyboard as faKeyboardSolid,
  faEye,
  faEyeSlash,
  faSignIn
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope as faEnvelopeRegular,
  faKeyboard as faKeyboardRegular
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { bus, regex } from "@/main";

library.add(
  faEnvelopeRegular,
  faEnvelopeSolid,
  faKeyboardRegular,
  faKeyboardSolid,
  faEye,
  faEyeSlash,
  faSignIn
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class Login extends Vue {
  public username = "";
  public password = "";

  public valids = {
    username: false,
    password: false
  };
  public showPassword = false;

  public checkValid(name: string): void {
    let valid = false;
    switch (name) {
      case "username":
        if (this.username.match(regex.email)) {
          valid = true;
        }
        break;
      case "password":
        if (this.password.match(regex.password)) {
          valid = true;
        }
        break;
    }
    Vue.set(this.valids, name, valid);
  }

  public isFormValid(): boolean {
    return !Object.values(this.valids).includes(false);
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public async handleSubmit(): Promise<void> {
    if (!this.isFormValid()) {
      return;
    }
    try {
      await Auth.signIn(this.username, this.password);
      bus.$emit("authChange");
      bus.$emit("flashNotice", {
        type: "success",
        message: "Login successful"
      });
      this.$router.push({ name: "upload" });
    } catch (err) {
      let errorType = "error";
      let errorMessage = "Failed: Something went wrong.";

      if (
        ["UserNotFoundException", "NotAuthorizedException"].includes((err as { code:string }).code)
      ) {
        errorMessage = "Incorrect username or password.";
      } else if (["UserNotConfirmedException"].includes((err as { code:string }).code)) {
        errorType = "warning";
        errorMessage =
          "This account has been registered, but requires activation from an admin before it can be used.";
      }

      bus.$emit("flashNotice", {
        type: errorType,
        message: `Login unsuccessful: ${errorMessage}` // Process error messages
      });
    }
  }
}
