
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck, faCode, faEnvelope, faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { bus, regex } from "@/main";
import { Auth } from "aws-amplify";
import VueRouter from "vue-router";
import router from "@/router";

library.add(
  faCheck,
  faEnvelope,
  faRefresh,
  faCode,
);

@Component({
  components: {
    
  }
})

export default class VerifyEmail extends Vue {
  public user:any;
  public email = "";
  public codeSent = false;

  public verificationCode = "";

  public valids = {
    verificationCode: false,
  };

  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.email = this.user.attributes.email;
  }

  public async sendCode() {
    try {
      this.user.getAttributeVerificationCode("email", {
        onSuccess: function(result:any) {
          bus.$emit("flashNotice", {
            type: "success",
            message: `Verification code sent`
          });
        },
        onFailure: function (error:any) {
          bus.$emit("flashNotice", {
          type: "error",
          message: `Error: ${(error as { message:string }).message}`
        });
        }
      })
      this.codeSent = true;
    } catch(err: any) {
      bus.$emit("flashNotice", {
          type: "error",
          message: `Error: ${(err as { message:string }).message}`
        });
    };
  }

  public checkValid(name: string): void {
    let valid = false;
    switch (name) {
      case "verificationCode":
        if (this.verificationCode.length === 6) {
          valid = true;
        }
        break;
    }
    Vue.set(this.valids, name, valid);
  }

  

  public async handleVerificationCode() {
    this.user.verifyAttribute("email", this.verificationCode, {
        onSuccess: async function(result:any) {
          bus.$emit("flashNotice", {
            type: "success",
            message: `Email address has been verified`
          }); 
          const userUpdate = await Auth.currentAuthenticatedUser({ bypassCache: true });
          router.push('/');
        },
        onFailure: function (error:any) {
          bus.$emit("flashNotice", {
            type: "error",
            message: `Error: ${(error as { message:string }).message}`
          });
        }
      })
    return;
  }
}
