/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:c2ce58dd-650e-4b3e-8967-029e88af604a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_bojzQQWm8",
    "aws_user_pools_web_client_id": "5bc5g7mi7kt545kitrnc2kv0q9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ukc-owcat-files124302-newprod",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "1dc859d6af5f487aa192fc9d88701b87",
    "aws_mobile_analytics_app_region": "eu-west-2"
};


export default awsmobile;
