
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDownload,
  faFileExcel as faFileExcelSolid,
  faUpload,
  faRefresh,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel as faFileExcelRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Auth, Storage, Analytics } from "aws-amplify";
import { bus } from "@/main";

library.add(
  faDownload,
  faFileExcelSolid,
  faUpload,
  faRefresh,
  faThumbsUp,
  faFileExcelRegular
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class OWCATInput extends Vue {
  private allowedExtensions: string[] = ["xls", "xlsx"];

  public fileList: string[] = [];
  public uploading = false;
  public percentage = 0;
  public isHovered = false;
  public uploaded = false;
  public uploadedFileName = "";
  public userEmail = "";

  public refreshKey = 0;

  mounted() {
    window.addEventListener(
      "dragover",
      function(e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function(e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
  }

  public hover(): void {
    this.isHovered = true;
  }

  public unhover(): void {
    this.isHovered = false;
  }

  private get fileInput(): HTMLInputElement {
    return this.$refs.fileInput as HTMLInputElement;
  }

  public fileDrop(event: any): void {
    event.stopPropagation();
    this.unhover();
    this.fileInput.files = event.dataTransfer.files;

    this.previewFiles();
  }

  public resetForm(): void {
    this.uploaded = false;
    this.uploadedFileName = "";
    this.removeFiles();
  }

  public prepPreviewFiles(event: any): void {
    const target = event.target as HTMLInputElement;

    this.fileInput.files = target.files;
    this.previewFiles();
  }

  public previewFiles(): void {
    const files: ReadonlyArray<File> = [
      ...(this.fileInput.files ? this.fileInput.files : [])
    ];
    files.every(file => {
      const extension = file.name.split(".").pop() || "";
      if (!this.allowedExtensions.includes(extension)) {
        this.removeFiles();
        bus.$emit("flashNotice", {
          type: "error",
          message: `Failed: Incorrect file extension. ${this.allowedExtensions.join(
            "/"
          )} expected. ${extension} found.`
        });
        return false;
      } else {
        Vue.set(this.fileList, this.fileList.length, file.name);
      }
    });
  }

  public removeFiles(): void {
    this.fileInput.value = "";
    this.fileList = [];
  }

  public async downloadExample(): Promise<void> {
    const result = await Storage.get("downloads/owcat-inputs-template.xlsx", { download: true });
    const blob = result.Body as Blob;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "owcat-inputs-template.xlsx";
    link.click();
    URL.revokeObjectURL(link.href);
  }

  public isUploadDisabled(): boolean {
    return this.fileList.length === 0;
  }

  public uploadComplete() {
    this.percentage = 0;
    this.uploading = false;

    this.uploaded = true;

    this.uploadedFileName = this.fileList[0];

    bus.$emit("flashNotice", {
      type: "success",
      message: `Upload successful`
    });

    this.removeFiles();
  }

  public async uploadFile(): Promise<void> {
    Analytics.record({ name: "fileUpload" });

    if (this.isUploadDisabled()) {
      return;
    }

    const user = await Auth.currentUserInfo();
    this.userEmail = user.attributes.email;
    const now: Date = new Date();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    this.uploading = true;

    // Linter complains without this check
    if (!this.fileInput.files) {
      bus.$emit("flashNotice", {
        type: "error",
        message: "No file found. Please refresh and try again"
      });
      return;
    }
    const file = this.fileInput.files[0];
    const reader = new FileReader();

    reader.onload = e => {
      // Linter complains without this check
      if (!e.target) {
        bus.$emit("flashNotice", {
            type: "error",
            message: "There was an error reading your file. Please refresh and try again"
          }
        );
        return;
      }
      const fileContents = e.target.result;
      Storage.put(
        `uploads/${now.getTime()}-${user.username}-inputs.xlsx`,
        fileContents,
        {
          contentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          tagging: "cleanup1d",
          metadata: {
            originalfilename: file.name,
            email: this.userEmail
          },
          progressCallback(progress) {
            that.percentage = (100 / progress.total) * progress.loaded;

            if (that.percentage === 100) {
              that.uploadComplete();
            }
          }
        }
      );
    };

    reader.onerror = () => {
      bus.$emit("flashNotice", {
        type: "error",
        message: `Failed: Something went wrong.`
      });
    };

    reader.readAsArrayBuffer(file);
  }
}
