import Vue from "vue";
import VueRouter from "vue-router";
import UploadPage from "../views/UploadPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import LoginPage from "../views/LoginPage.vue";
import ForgotPassPage from "../views/ForgotPassPage.vue";
import VerifyEmailPage from "../views/VerifyEmailPage.vue";
import NotFoundPage from "../views/NotFoundPage.vue";

import { Auth } from "aws-amplify";
import { bus } from "@/main";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "upload",
    component: UploadPage,
    meta: { requiresAuth: true }
  },

  {
    path: "/register",
    name: "regsiter",
    component: RegisterPage,
    meta: { requiresNoAuth: true }
  },
  {
    path: "/forgot-pass",
    name: "forgot-pass",
    component: ForgotPassPage,
    meta: { requiresNoAuth: true }
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifyEmailPage,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: { requiresNoAuth: true }
  },
  {
    path: "*",
    name: "404",
    component: NotFoundPage
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user: { attributes: { email_verified: boolean; }; }; //???
    Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          user = data;
        }
        if (to.path!= "/verify" && !user.attributes.email_verified) {
          next({
            path: "/verify",
            query: {
              redirect: to.fullPath
            }
          })
        }
      })
      .catch(_ => {
        next({
          path: "/login",
          query: {
            redirect: to.fullPath
          }
        });
      });
  } else if (to.matched.some(record => record.meta.requiresNoAuth)) {
    Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          next({ name: "404" });
        }
      })
      .catch(_ => {
        // bus.$emit("flashNotice", {
        //   type: "error",
        //   message: `Failed: ${err.message}`
        // });
        next({ path: to.path });
      });
  }
  next();
});

export default router;
