<template>
  <div>
    <VerifyEmail />
  </div>
</template>

<script>
// @ is an alias to /src
import VerifyEmail from "@/components/VerifyEmail.vue";

export default {
  name: "VerifyEmailPage",
  components: {
    VerifyEmail
  }
};
</script>
