<template>
  <div>
    <h2>Page Not Found</h2>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "NotFoundPage",
  components: {
  }
};
</script>
