import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Amplify, { Analytics } from "aws-amplify";

import awsmobile from "./aws-exports";

import Header from "@/components/Header.vue";

Amplify.configure(awsmobile);

Vue.component("Header", Header);

Vue.config.productionTip = false;

export const bus = new Vue();

export const regex = {
  email: new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/
  ),
  password: new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  )
};

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
