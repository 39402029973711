<template>
  <div>
    <ForgotPass />
  </div>
</template>

<script>
// @ is an alias to /src
import ForgotPass from "@/components/ForgotPass.vue";

export default {
  name: "ForgotPassPage",
  components: {
    ForgotPass
  }
};
</script>
