
import { Component, Vue } from "vue-property-decorator";
// import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import { Auth } from "aws-amplify";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faSignOut
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { bus } from "@/main";

library.add(faCheckCircle, faExclamationTriangle, faTimesCircle, faSignOut);

Vue.component("font-awesome-icon", FontAwesomeIcon);

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class Header extends Vue {
  public isLoggedIn = true;
  public user = { name: "" };
  public flashQueue: Record<string, string>[] = [];

  async mounted() {
    this.checkAuth();

    bus.$on("authChange", () => {
      this.checkAuth();
    });

    bus.$on("flashNotice", (content: Record<string, string>) => {
      this.addFlash(content);
    });
  }

  public addFlash(content: Record<string, string>): void {
    this.flashQueue.push(content);
    setTimeout(() => {
      this.flashQueue.shift();
    }, 4000);
  }

  public async logout(): Promise<void> {
    try {
      await Auth.signOut();
      this.$router.push({ name: "login" });
      this.isLoggedIn = false;
      bus.$emit("flashNotice", {
        type: "warning",
        message: "Logout successful"
      });
      // eslint-disable-next-line
    } catch (err) {
      bus.$emit("flashNotice", {
        type: "error",
        message: `Logout failed: Something went wrong, please try again`
      });
    }
  }

  public async checkAuth(): Promise<void> {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      this.user = currentAuthUser.attributes;
      this.isLoggedIn = true;
    } catch (err) {
      this.isLoggedIn = false;
    }
  }
}
